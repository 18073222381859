/**
 * Default: Components / Overwrites / Bootstrap > Modal
 *
 * @copyright 2024 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {getTabbableElements} from '../../../../shared/utils/tabbable';
import FocusTrap from '../../../../shared/utils/focustrap';

import SelectorEngine from '../../../../shared/dom/selector-engine';
import EventHandler from '../../../../shared/dom/event-handler';
import Manipulator from '../../../../shared/dom/manipulator';
import Data from '../../../../shared/dom/data';

// -------
// Private
// -------

const NAME      = 'modal';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;

const manipulateTrigger = (elements, flag) => {
	for (const element of elements) {
		if(flag) {
			Manipulator.addClass(element, '_active');
		} else {
			Manipulator.removeClass(element, '_active');
		}
	}
};

const modalIframe = () => {
	const container = SelectorEngine.findOne('#modal-iframe');
	const controls  = (container) ? SelectorEngine.find('[data-trigger-modal-iframe]') : null;

	if (!controls) {
		return;
	}

	const modal     = new bootstrap.Modal(container);
	const modalBody = SelectorEngine.findOne('.modal-body', container);

	// Modal wird geöffnet, Daten eintragen.
	container.addEventListener('show.bs.modal', (event) => {
		const trigger = event.relatedTarget;
		const uri     = Data.get(trigger, `${DATA_KEY}.uri`);
		const context = trigger.dataset.triggerModalIframe;
		const iframe  = Manipulator.elementPrepend(`<iframe src="${uri}" scrolling="yes"></iframe>`, modalBody);

		Manipulator.addClass(container, context);

		Data.set(container, `${DATA_KEY}.context`, context);
		Data.set(container, `${DATA_KEY}.iframe`, iframe);
		Data.set(event.target, `${DATA_KEY}.currentTrigger`, trigger);

		Manipulator.addClass(trigger, '_active');

		manipulateTrigger(controls, true);

		window.pageInstance.currentIframeModal = iframe;

		// iframe.height = iframe.contentWindow.document.body.scrollHeight;
	});

	// Modal wurde geschlossen, Daten entfernen.
	container.addEventListener('hidden.bs.modal', (event) => {
		const trigger = Data.get(event.target, `${DATA_KEY}.currentTrigger`);
		const iframe  = Data.get(container, `${DATA_KEY}.iframe`);

		Manipulator.removeClass(container, Data.get(container, `${DATA_KEY}.context`));

		iframe.remove();

		window.pageInstance.currentIframeModal = null;

		manipulateTrigger(controls, false);

		// Set focus back to trigger.
		if (trigger) {
			setTimeout(() => trigger.focus());
		}
	});

	// Modal trigger holen und initialisieren.
	for (const control of controls) {
		const uri = control.dataset.uri;

		if (uri) {
			Data.set(control, `${DATA_KEY}.uri`, uri);

			EventHandler.on(control, `click${EVENT_KEY}`, (event) => {
				event.preventDefault();

				modal.show(event.delegateTarget);
			});

			delete control.dataset.uri;
		} else {
			Manipulator.setDisabled(control);
		}
	}
};

// ------
// Public
// ------

/**
 * ´Modal´-Elemente initialisieren.
 *
 * @returns {HTMLElement|Array}
 */
const init = () => {
	// Ist der aktuelle Kontext des Dokumentes ´modal´ (also ein Iframe), dann muss die aktuelle Höhe des Dokumentes an
	// das aktive Modal in `window.parent` übergeben werden.
	if (Manipulator.getDataAttribute(document.body, 'page-type') === 'modal') {
		const currentModal = window.parent.pageInstance.currentIframeModal;
		const container    = SelectorEngine.findOne('.page-main');

		if (currentModal) {
			const updateHeight = () => {
				setTimeout(() => {
					currentModal.style.setProperty(`--_modal-iframe-doc-scroll-height`, `${document.body.scrollHeight}px`);
				}, 50);
			};

			const modalResizeObserver = new ResizeObserver(entries => {
				if (entries[0].target) {
					updateHeight();
				}
			});

			modalResizeObserver.observe(container);

			updateHeight();
		}
	}

	modalIframe();
};

// Export
export default init;
