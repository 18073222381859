/**
 * Sources > Default > Vendors: Fancybox
 */

import SelectorEngine from '../../../shared/dom/selector-engine';
import Manipulator    from '../../../shared/dom/manipulator';

// -------
// Private
// -------

const preLinkHandling = async () => {
	//
	// Kontaktlinks vom RTE (Linkhandler)
	// Projektseitig gibt es kein Kontaktdetail, es wird ein
	// Nachricht-Senden-Modal geöffnet. Da Kontaktlinks aber per auch RTE
	// gesetzt werden können, müssen diese Links separat behandelt werden.
	//

	const rteContactLinks = SelectorEngine.find('a[href*="?tx_ifabcontact_ifabcontact%5Baction%5D=show"]');

	for (const link of rteContactLinks) {
		Manipulator.setDataAttribute(link, 'width', 1200);
		Manipulator.setDataAttribute(link, 'zoom', 'url');
	}

	return true;
};

const zoomImage = async () => {
	Fancybox.bind('[data-zoom="image"]', {
		compact     : false,
		contentClick: "iterateZoom",
		groupAttr   : 'data-zoom-group',
		type        : 'image',
		Carousel: {
			transition: 'slide'
		},
		Images      : {
			Panzoom  : {
				maxScale: 2
			},
			protected: true
		},
		Toolbar     : {
			display: {
				left  : [],
				middle: [],
				right : [
					'iterateZoom',
					'close'
				]
			}
		},
		Thumbs      : false
	});

	return true;
};

const zoomUrl = async () => {
	Fancybox.bind('[data-zoom="url"]', {
		mainClass  : `${Fancybox.defaults.mainClass} -type-url`,
		groupAttr  : false,
		type       : 'iframe',
		preload    : false,
		caption    : null,
		closeButton: 'outside'
	});

	return true;
};

// ------
// Public
// ------

const init = async () => {
	//
	// Pluginstandards.
	//

	// Fancybox.defaults.hideScrollbar = false;
	// Fancybox.defaults.mainClass     = 'fancybox-custom';
	//
	Fancybox.defaults.l10n = {
		CLOSE      : window.pageInstance.tm.translate('close'),
		DOWNLOAD   : window.pageInstance.tm.translate('download'),
		ITERATEZOOM: window.pageInstance.tm.translate('iterateZoom'),
		ERROR      : window.pageInstance.tm.translate('error'),
		MODAL      : window.pageInstance.tm.translate('closeEsc'),
		NEXT       : window.pageInstance.tm.translate('next'),
		PREV       : window.pageInstance.tm.translate('prev')
	};

	// Fancybox.defaults.tpl = {
	// 	closeButton: `<button
	// 		title="${window.pageInstance.tm.translate('close')}"
	// 		class="icon-button"
	// 		data-fancybox-close
	// 		type="button"
	// 	><span class="icon-button__icon">${window.pageInstance.config.icons.close}</span></button>`
	// };

	// Fancybox.defaults.Navigation.nextTpl = "Next page";
    // Fancybox.defaults.Navigation.prevTpl = "Previous page";


	// Fancybox.defaults.Carousel = {
	// 	Navigation: {
	// 		nextTpl: window.pageInstance.config.icons.next,
	// 		prevTpl: window.pageInstance.config.icons.prev
	// 	}
	// };

	Fancybox.defaults.caption = (fancybox, slide) => {
		const figureCaption = SelectorEngine.next(slide.triggerEl, '.figure__caption')[0];

		let caption = slide.caption || '';

		if (figureCaption) {
			caption = figureCaption.innerHTML;
		}

		return caption;
	};

	//
	// Verfügbare ´Zoomtypen´ initialisieren.
	//

	preLinkHandling()
		.then(() => {
			return zoomImage();
		})
		.then(() => {
			return zoomUrl();
		});
};

// Export
export default init;
