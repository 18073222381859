/**
 * Navigation
 */

import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../shared/utils/scroll';

import SelectorEngine from '../../../shared/dom/selector-engine';
import Manipulator from '../../../shared/dom/manipulator';
import EventHandler from '../../../shared/dom/event-handler';

import Nav from '../../../shared/components/nav';
import Drawer from '../../../shared/components/drawer';

// -------
// Private
// -------

const NAME      = 'menu';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY    = '.data-api';

const pageHeader = SelectorEngine.findOne('#page-header');

/**
 * Drawer (Navigationsoverlay)
 */
const setupDrawer = () => {
	const pageMenuDrawer = SelectorEngine.findOne('#page-menu-drawer');
	const nav            = (pageMenuDrawer) ? SelectorEngine.findOne('.main-nav', pageMenuDrawer) : null;
	const trigger        = SelectorEngine.findOne('[aria-controls="page-menu-drawer"]');

	if (!nav) {
		return;
	}

	const api = new Nav(nav, {
		closeByOutsideClick: false,
		closeByEsc         : false,
		labelSubmenu       : (window.pageInstance.tm) ? window.pageInstance.tm.translate('ariaLabelSubmenu') : 'Submenu for %s',
		labelSubmenuTrigger: (window.pageInstance.tm) ? window.pageInstance.tm.translate('ariaLabelSubmenuTrigger') : 'Show submenu for %s',
		useReveal          : {
			visibleDisplay: 'flex'
		}
	});

	api.showCurrentTree();

	const drawer  = new Drawer(pageMenuDrawer, {
		closeButton: false,
		placement: 'end',
		onInit   : (event) => {
			// const curPageItem = SelectorEngine.findOne('[aria-current="page"]', nav);
			//
			// if (curPageItem) {
			// 	scrollElementIntoView(curPageItem, appMenuBody, 'vertical');
			// }
		},
		onShow   : () => {
			api.showCurrentTree();

			Manipulator.addClass(trigger, '-active');
		},
		onHidden : () => {
			Manipulator.removeClass(trigger, '-active');
		}
	});

	if (trigger) {
		EventHandler.on(trigger, 'click.ifab.pageMenuDrawer', (event) => {
			event.preventDefault();
			event.stopPropagation();

			if (event.delegateTarget.classList.contains('-active')) {
				drawer.hide();
			} else {
				drawer.show();
			}
		});
	}
};

/**
 * Initialisierung der eigentlichen Navigationsliste.
 */
const setupDefault = () => {
	const nav = (pageHeader) ? SelectorEngine.findOne('[data-nav="main"]') : null;

	if (nav) {
		const api = new Nav(nav, {
			closeByOutsideClick: true,
			closeByEsc         : true,
			labelSubmenu       : (window.pageInstance.tm) ? window.pageInstance.tm.translate('ariaLabelSubmenu') : 'Submenu for %s',
			labelSubmenuTrigger: (window.pageInstance.tm) ? window.pageInstance.tm.translate('ariaLabelSubmenuTrigger') : 'Show submenu for %s',
			useReveal          : true
		});
	}
};

// -------
// Public
// -------

const menu = () => {
	setupDefault();
	setupDrawer();
};

// Export
export default menu;
