/**
 * Default: Components / Overwrites / Shared > Form
 *
 * @copyright 2024 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import SelectorEngine from '../../../../shared/dom/selector-engine';
import EventHandler from "../../../../shared/dom/event-handler";
import Data from '../../../../shared/dom/data';

import Form from '../../../../shared/components/form';

// -------
// Private
// -------

// ------
// Public
// ------

/**
 * ´Modal´-Elemente initialisieren.
 *
 * @returns {HTMLElement|Array}
 */
const init = () => {
	// const formFields = SelectorEngine.find('.form form-control, .form .form-select');
	//
	// for (const field of formFields) {
	// 	EventHandler.on(field, 'validationFailed.ifab', () => {
	//
	// 	});
	//
	// 	EventHandler.on(field, 'validationFailed.ifab', () => {
	//
	// 	});
	// }

	Form.init();
};

// Export
export default {
	init: init
};


