/**
 * Default: Components > Custom > Content slider
 *
 * @copyright 2024 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	debounce,
	setRootVar
} from "../../../../shared/utils";

import SelectorEngine from '../../../../shared/dom/selector-engine';
import EventHandler from '../../../../shared/dom/event-handler';

import Manipulator from '../../../../shared/dom/manipulator';
import Slider from '../../../../shared/components/slider';

// -------
// Private
// -------

const NAME     = 'contentslider';
const DATA_KEY = `ifab.${NAME}`;
const EVENT_KEY  = `.${DATA_KEY}`;
// const API_KEY    = '.data-api';

let collection = [];
let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const checkSpace = () => {
	screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

	for (const element of collection) {
		EventHandler.trigger(element, `${EVENT_KEY}.checkSpace`);
	}
};

// ------
// Public
// ------

/**
 * ´Button´-Elemente zusammenstellen und initialisieren.
 *
 * @returns {HTMLElement|Array}
 */
const init = () => {
	collection = SelectorEngine.find(`[data-c="${NAME}"]`);

	if (collection.length > 0) {
		const o = {
			default: {
				autoHeight     : false,
				grabCursor     : true,
				slidesPerView  : 'auto',
				spaceBetween   : 0
			},
			feature: {
				autoHeight     : false,
				effect         : 'coverflow',
				grabCursor     : true,
				centeredSlides : true,
				slidesPerView  : 'auto',
				spaceBetween   : 0,
				coverflowEffect: {
					rotate  : 0,
					stretch : 0,
					depth   : 0,
					modifier: 1
				}
			}
		};

		for (const element of collection) {
			const context = element.dataset.contentsliderContext ?? 'default';

			let options = o.default;

			if (o[context] !== undefined) {
				options = o[context];
			}

			EventHandler.on(element, `${EVENT_KEY}.checkSpace`, (event) => {
				const slide       = SelectorEngine.findOne(`.${NAME}-item`, event.target);
				const button      = SelectorEngine.findOne('.swiper-button-prev', event.target);
				const columns     = parseInt(event.target.dataset.contentsliderColumns ?? 1, 10);

				let controlTopOffset = '50%';

				if (context === 'video') {
					const playerMedia = SelectorEngine.find(`.swiper-slide-active .${NAME}-item .player-media, .${NAME}-item .player-media`, event.target);

					if (playerMedia[0]) {
						const _gbc = playerMedia[0].getBoundingClientRect();

						controlTopOffset = `${_gbc.height}px`;
					}
				}

				// Zielbreite, die für die Sliderbuttons ´links & rechts´ benötigt wird, berechnen.
				// Wir geben den buttons sicherheitshalber mal noch 2 Pixel mehr dazu.
				const targetWidth = (slide.clientWidth * columns) + ((button.clientWidth + 2 ) * 2);
				const diff = ((screenWidth - targetWidth) / 2);

				// Differenz zwischen `screenWidth` & `targetWidth`, dient zum Positionieren der Buttons, bekanntgeben.
				setRootVar('_enough-space-diff', `${(diff > 0) ? diff : 0}px`, event.target);
				// Höhe des Slideitems.
				setRootVar('_control-top-offset', controlTopOffset, event.target);

				if (targetWidth < screenWidth) {
					Manipulator.removeClass(event.target, '-not-enough-space');
					Manipulator.addClass(event.target, '-enough-space');
				} else {
					Manipulator.removeClass(event.target, '-enough-space');
					Manipulator.addClass(event.target, '-not-enough-space');
				}
			});

			Slider.init(element, options);
		}

		checkSpace();

		window.addEventListener('resize', debounce(() => {
			checkSpace();
		}, 150));

		window.addEventListener('orientationchange', checkSpace);
	}

	return collection;
};

// Export
export default {
	init  : init
};
