/**
 * Components
 */

import Button from '../../shared/components/button';
import Details from '../../shared/components/details';
// import GMap           from '../../shared/components/gmap';
import TabGroup from '../../shared/components/tab-group';
import Slider from '../../shared/components/slider';
import Form from './shared/form';
import Jumplist from '../../shared/components/jumplist';
import List from '../../shared/components/list';
import MediaGallery from '../../shared/components/media-gallery';
import Player from '../../shared/components/player';
// import ProgressBar    from '../../shared/components/progress-bar';
// import ProgressRing   from '../../shared/components/progress-ring';
// import RevealPassword from '../../shared/components/reveal-password';
// import ScrollProgress from '../../shared/components/scroll-progress';
import Tag from '../../shared/components/tag';
import Table from './shared/table';
import FeatureComparison from './custom/featurecomparison';
import ContentSlider from './custom/contentslider';
import Timeline from './custom/timeline';

// ------
// Public
// ------

const components = () => {
	Button.init();
	Details.init();
	TabGroup.init(null, {
		showIndicator: false
	});
	Slider.init();
	Form.init();
	Jumplist.init({
		label: 'On this page'
	});
	List.init();
	MediaGallery.init();
	FeatureComparison.init();
	ContentSlider.init();
	Timeline.init();
	Player.init({
		audio: {
			volume: 0.5
		},
		video: {
			volume: 0.5
		}
	});
	// ProgressBar.init();
	// ProgressRing.init();
	// RevealPassword.init();
	// ScrollProgress.init();
	Tag.init();

	Table.makeScrollable();

	// const gMapAllowed = (typeof window.ifabCookieConsent !== 'undefined') ? window.ifabCookieConsent.getConsent('googlemaps') : true;
	//
	// if (gMapAllowed && typeof window.pageInstance.settings.maps.google.id) {
	// GMap.init(null, {
	// 	apiKey  : window.pageInstance.settings.maps.google.id,
	// 	settings: {
	// 		// Siehe google-maps.js -> DEFAULTS
	// 		map: {
	// 			zoom: 14
	// 		}
	// 	}
	// });
	// }
};

// Export
export default components;
