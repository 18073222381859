/**
 * Vendors ´Cookie consent´
 */

import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../shared/utils/scroll';

import SelectorEngine from '../../../shared/dom/selector-engine';
import EventHandler from '../../../shared/dom/event-handler';

import Matomo from './tools/matomo';

// -------
// Private
// -------

// const MatomoInstance = null;
const MatomoInstance = new Matomo(null, {
	// setCookieDomain : '*.relaunch.landkreis-nordsachsen.de',
	siteId       : (window.pageInstance.settings.statistic.matomo === undefined) ? 0 : window.pageInstance.settings.statistic.matomo.id,
	trackerDomain: (window.pageInstance.settings.statistic.matomo === undefined) ? '' : window.pageInstance.settings.statistic.matomo.library
	// Argumente `_paq.push` (beim Init und/oder der De-, Aktivierung)
	// Value = '', nur Schlüsselname übermitteln/setzen
	// Value = null, kein Schlüsselname übermitteln/setzen
	// Value != '' & != null, Schlüsselname mit Value übermitteln/setzen
	// paramsWhenInit   : {},
	// paramsWhenDisable: {},
	// paramsWhenEnable : {},
	// onEnable         : () => {},
	//onDisable         : () => {}
});

// const GtmInstance = null;
// const GaInstance = null;

const checkCatMedia = (data) => {
	if (!data) {
		return;
	}

	// Youtube
	const ytCollection = SelectorEngine.find('[data-player-type="youtube"]');
	for (const element of ytCollection) {
		EventHandler.trigger(element, `${data.includes('youtube') ? 'disable' : 'enable'}.privacyMode`);
	}

	// Vimeo
	const viCollection = SelectorEngine.find('[data-player-type="vimeo"]');
	for (const element of viCollection) {
		EventHandler.trigger(element, `${data.includes('vimeo') ? 'disable' : 'enable'}.privacyMode`);
	}

	// Googlemap
	const gmCollection = SelectorEngine.find('[data-gmap]');
	for (const element of gmCollection) {
		EventHandler.trigger(element, `${data.includes('googlemaps') ? 'disable' : 'enable'}.privacyMode`);
	}
};

const checkCatStatistic = (data) => {
	if (!data) {
		return;
	}

	// Matomo
	if (MatomoInstance && MatomoInstance.isReady) {
		if (data.includes('matomo')) {
			MatomoInstance.enable();
		} else {
			MatomoInstance.disable();
		}
	}

	// Google Analytics (WIP
	// if (data.includes('googleanalytics')) {
	// 	document.cookie                  = 'ga-disable-UA-129301-1=false; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
	// 	window['ga-disable-UA-129301-1'] = false;
	// 	INSERT_GTM();
	// } else {
	// 	document.cookie                  = 'ga-disable-UA-129301-1=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
	// 	window['ga-disable-UA-129301-1'] = true;
	// 	INSERT_GTM();
	// }
};

// ------
// Public
// ------

const init = () => {
	let inst = null;

	if (typeof ScCookie !== 'undefined') {
		inst = new ScCookie({
			on: {
				showPreferences: function () {
					lockBodyScrolling(this);
				},
				hidePreferences: function () {
					unlockBodyScrolling(this);
				},
				saveConsent: function () {
					// document.location.reload();
					checkCatMedia(this.getConsent('media'));
					checkCatStatistic(this.getConsent('statistics'));
				},
				init: function (consent) {
					checkCatMedia(this.getConsent('media'));
					checkCatStatistic(this.getConsent('statistics'));
				}
			}
		});
	}

	// Video: Aktivieren und abspielen
	// EventHandler.on(document.body, 'click', '[data-privacy-enable]', (ev) => {
	// 	console.log(Manipulator.getDataAttribute(ev.delegateTarget, 'privacy-enable'));
	// });

	// Custom-Trigger für Cookie-Einstellungen anzeigen.
	EventHandler.on(document.body, 'click', '[data-privacy-settings]', (event) => {
		event.stopPropagation();

		window.ifabCookieConsent.togglePreferences();
	});

	return window.ifabCookieConsent = inst;
};

// Export
export default init;
