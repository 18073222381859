/**
 * Default: Components > Custom > Timeline
 *
 * @copyright 2024 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {getTransitionDelay} from "../../../../shared/utils";

import SelectorEngine from '../../../../shared/dom/selector-engine';
import EventHandler from '../../../../shared/dom/event-handler';
import Manipulator from '../../../../shared/dom/manipulator';
import Data from "../../../../shared/dom/data";

import Reveal from '../../../../shared/components/reveal';

// -------
// Private
// -------

const NAME     = 'timeline';
const DATA_KEY = `ifab.${NAME}`;
const EVENT_KEY  = `.${DATA_KEY}`;
// const API_KEY    = '.data-api';

/**
 * ´Timeline´-Element initialisieren.
 *
 * @param {HTMLElement} element
 */
const render = (element) => {
	// Wurde Element schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return element;
	}

	const toggle = SelectorEngine.findOne(`[data-${NAME}-toggle]`, element);
	const items = SelectorEngine.find(`.${NAME}-item[data-visible]`, element);

	if (items.length > 0 && toggle) {
		let multiplier = 0;

		for (const item of items) {
			const gTD = getTransitionDelay(item);

			item.style.setProperty(`transition-delay`, `${(gTD / 1000) * multiplier}s`);

			multiplier += 0.125;
		}

		EventHandler.on(toggle, `click.${EVENT_KEY}` , event => {
			event.preventDefault();

			const pressed = !Manipulator.getAria(event.target, 'pressed');

			Manipulator.setAria(event.target, 'pressed', pressed);

			for (const item of items) {
				if (pressed) {
					Reveal
						.show(item, {
							visibleDisplay: 'flex'
						})
						.then(element => {
							Manipulator.setDataAttribute(item, 'visible', 'true');
						});
				} else {
					Reveal
						.hide(item)
						.then(element => {
							Manipulator.setDataAttribute(item, 'visible', 'false');
						});
				}
			}
		});
	}

	// Initialisierungsstatus setzen.
	Data.set(element, `${DATA_KEY}.initialized`, true);
};

// ------
// Public
// ------

/**
 * ´Button´-Elemente zusammenstellen und initialisieren.
 *
 * @returns {HTMLElement|Array}
 */
const init = () => {
	const collection = SelectorEngine.find(`[data-${NAME}]`);

	if (collection.length > 0) {
		for (const element of collection) {
			render(element);
		}
	}

	return collection;
};

// Export
export default {
	init  : init
};
