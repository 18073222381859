/**
 * Initial
 */

import { normalizeAttributeValue, setRootVar } from '../../shared/utils/index';
import { focusVisible } from '../../shared/utils/focus-visible';
import { scrollTop } from '../../shared/utils/scroll';
import { load } from '../../shared/utils/load';
import lazyLoad from '../../shared/utils/lazy-load';

import SelectorEngine from '../../shared/dom/selector-engine';
import Manipulator from '../../shared/dom/manipulator';
import EventHandler from '../../shared/dom/event-handler';

/**
 * Monitor ´document.documentElement´ changes.
 *
 * @type {MutationObserver}
 */
const observerDocumentMutation = new MutationObserver((mutations) => {
	EventHandler.trigger(document.documentElement, 'ifab.documentMutation', {
		mutations
	});
});

const observerDocHeight = () => {
	const calc = () => {
		setRootVar(`${window.pageInstance.prefix}vh`, `${window.innerHeight/100}px`);
	};

	window.addEventListener('resize', calc);
	window.addEventListener('orientationchange', calc);

	calc();
};

const observerDocScrollDirection = () => {
	const scrollDiff = 100;

	let currentScrollPos = window.scrollY ?? 0;

	document.addEventListener('scroll', () => {
		requestAnimationFrame(() => {
			if ((currentScrollPos + scrollDiff) < window.scrollY) {
				currentScrollPos                          = window.scrollY;
				document.documentElement.dataset.scrolled = 'down';
			} else if ((currentScrollPos - scrollDiff) > window.scrollY) {
				currentScrollPos                          = window.scrollY;
				document.documentElement.dataset.scrolled = 'up';
			}
		});
	}, {
		passive: true
	});
};

/**
 * Accessibility (`:focus` erst bei Tastaturnavigation anzeigen.)
 */
const handleFocusableElements = () => {
	const collection = SelectorEngine.find('input, textarea, select, label, a, button');

	for (const element of collection) {
		focusVisible.observe(element);
	}
};

const bindLazyload = () => {
	// Fehlerbehandlung von ´fehlenden Bildern´ anbinden.
	const images = SelectorEngine.find('img');

	for (const image of images) {
		EventHandler.on(image, 'error', (event) => {
			Manipulator.addClass(event.target, 'is-faulty');
		});
	}

	// Lazyload initialisieren.
	lazyLoad(function () {
		const lazyElements = SelectorEngine.find('.lazyload[data-src]');

		if (lazyElements) {
			window.lazySizesConfig = window.lazySizesConfig || {};
			window.lazySizesConfig.loadMode = 1;

			load(`${window.pageInstance.path.dist}vendors/lazysizes/lazysizes.min.js`).then(() => {
				// Do nothing...
			});
		}
	});
};

const elementsColorScheme = (colorScheme = 'light') => {
	const elements = SelectorEngine.find('[data-cs-class-light][data-cs-class-dark]');

	for (const element of elements) {
		const clLight = element.dataset.csClassLight;
		const clDark = element.dataset.csClassDark;

		if (colorScheme === 'dark') {
			Manipulator.removeClass(element, clLight);
			Manipulator.addClass(element, clDark);
		} else {
			Manipulator.removeClass(element, clDark);
			Manipulator.addClass(element, clLight);
		}
	}
};

const handleColorScheme = () => {
	const toggles = SelectorEngine.find('[data-toggle="color-scheme"]');

	let currentScheme = window.getColorScheme();

	for (const toggle of toggles) {
		Manipulator.setAria(toggle, 'pressed', (currentScheme === 'dark') ? 'true' : 'false');

		EventHandler.on(toggle, 'click', (event) => {
			currentScheme = window.getColorScheme();

			const targetScheme = (currentScheme === 'dark') ? 'light' : 'dark';

			event.preventDefault();

			for (const toggle of toggles) {
				Manipulator.setAria(toggle, 'pressed', (targetScheme === 'light') ? 'false' : 'true');
			}

			window.setColorScheme(targetScheme);

			elementsColorScheme(targetScheme);
		});
	}

	elementsColorScheme(currentScheme);
};

// -------
// Public
// -------

const initial = () => {
	observerDocumentMutation.observe(document.documentElement, {
		attributes: true
	});

	handleColorScheme();
	bindLazyload();
	handleFocusableElements();
	observerDocHeight();
	observerDocScrollDirection();

	// ´History back´ - Buttons
	EventHandler.on(document.body, 'click', 'button[data-history-back]', (event) => {
		event.preventDefault();
		event.stopPropagation();

		history.back();
	});

	// ´Scroll top´ - Elwmente
	EventHandler.on(document, 'click', '[data-scroll-top]', (event) => {
		// preventDefault only for <a> elements (which change the URL) not inside the collapsible element
		if (
			event.target.tagName.toLowerCase() === 'a' ||
			(event.delegateTarget && event.delegateTarget.tagName.toLowerCase() === 'a')
		) {
			event.preventDefault();
		}

		scrollTop().then(element => {
			// `element` = zu ihm wird gescrollt
			// Do nothing ...
		});
	});

	// ...globalen ´Scroll top´-Button integrieren
	const scrollTopTrigger = SelectorEngine.findOne('button[data-scroll-top="global"]');

	if (scrollTopTrigger) {
		document.addEventListener('scroll', function () {
			requestAnimationFrame(() => {
				if (window.scrollY > window.innerHeight) {
					Manipulator.addClass(scrollTopTrigger, '-show');
				} else {
					Manipulator.removeClass(scrollTopTrigger, '-show');
				}
			});
		});
	}
};

// Export
export default initial;
