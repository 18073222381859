/**
 * Default: Components > Custom > Feature comparison
 *
 * @copyright 2024 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	debounce,
	setRootVar
} from "../../../../shared/utils";

import SelectorEngine from '../../../../shared/dom/selector-engine';
import EventHandler from '../../../../shared/dom/event-handler';
import Data from "../../../../shared/dom/data";

// -------
// Private
// -------

const NAME     = 'featurecomparison';
const DATA_KEY = `ifab.${NAME}`;
const EVENT_KEY  = `.${DATA_KEY}`;
// const API_KEY    = '.data-api';

let collection = [];

const updateTableHeaderSize = () => {
	for (const table of collection) {
		EventHandler.trigger(table, `${EVENT_KEY}.calcHeaderSize`);
	}
};

// ------
// Public
// ------

/**
 * ´Button´-Elemente zusammenstellen und initialisieren.
 *
 * @returns {HTMLElement|Array}
 */
const init = () => {
	collection = SelectorEngine.find(`[data-c="${NAME}"] .table`);

	if (collection.length > 0) {
		for (const table of collection) {
			if (!Data.get(table, `${DATA_KEY}.initialized`)) {
				const tableHead      = SelectorEngine.findOne('thead tr', table);
				const tableRows      = SelectorEngine.find('tr', table);
				const tableRowHeader = SelectorEngine.find('tr.colgroup-header', table);

				let index = tableRows.length + 1;

				for (const rowHead of tableRowHeader) {
					index++;

					rowHead.style.zIndex = index;
				}

				tableHead.style.zIndex = index + tableRowHeader.length;

				Data.set(table, 'table.head', tableHead);

				EventHandler.on(table, `${EVENT_KEY}.calcHeaderSize`, (event) => {
					const head =  Data.get(event.target, 'table.head');

					// Differenz zwischen `screenWidth` & `targetWidth`, dient zum Positionieren der Buttons, bekanntgeben.
					setRootVar('_table-header-height', `${head.clientHeight}px`, event.target);
				});

				// Initialisierungsstatus setzen.
				Data.set(table, `${DATA_KEY}.initialized`, true);
			}
		}

		window.addEventListener('resize', debounce(() => {
			updateTableHeaderSize();
		}, 100));

		window.addEventListener('orientationchange', updateTableHeaderSize);

		updateTableHeaderSize();
	}

	return collection;
};

// Export
export default {
	init  : init
};
