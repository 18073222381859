/**
 * Footer
 */

import {
	debounce,
	setRootVar
} from '../../../shared/utils/index';
import {observeInView} from '../../../shared/utils/observer';

import SelectorEngine from '../../../shared/dom/selector-engine';
import Manipulator    from '../../../shared/dom/manipulator';

// -------
// Privat
// -------

let footerContainer = null;

/**
 * Überwachung wann der Footer im ´Viewport´ erscheint.
 *
 * @param container
 */
const footerObserve = (container) => {
	observeInView(container, {
		onEnterViewport: () => {
			Manipulator.addClass(document.documentElement, 'page-footer-in-view');
		},
		onLeaveViewport: () => {
			Manipulator.removeClass(document.documentElement, 'page-footer-in-view');
		},
		observer       : {
			threshold: 0
		}
	});
};

// -------
// Public
// -------

const footer = () => {
	const useFooterContainer = footerContainer || SelectorEngine.findOne('#page-footer');

	if (useFooterContainer)  {
		footerObserve(useFooterContainer);

		// Oberen Abstand des Seitenfußes setzen wenn Element `.get-in-touch` vorhanden ist.
		const getInTouch = SelectorEngine.findOne('.get-in-touch', useFooterContainer);

		if(getInTouch) {
			setRootVar('_offset-top', `${getInTouch.offsetHeight}px`, useFooterContainer);

			window.addEventListener('resize', debounce(() => {
				setRootVar('_offset-top', `${getInTouch.offsetHeight}px`, useFooterContainer);
			}, 200));
		}
	}
};

// Export
export default footer;
