import VendorsCookieConsent from './cookie-consent';
import VendorsFancybox      from './fancybox';
import VendorsModal      from './bootstrap/modal';

const vendors = () => {
	/* eslint-disable no-console */
	// if (processEnv === 'development') {
	// 	console.log('Vendors');
	// }
	/* eslint-enable no-console */

	VendorsCookieConsent();
	VendorsFancybox();
	VendorsModal();
};

export default vendors;
