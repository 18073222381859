/**
 * Search
 */

import SelectorEngine from '../../../shared/dom/selector-engine';
import EventHandler from '../../../shared/dom/event-handler';
import Manipulator from '../../../shared/dom/manipulator';
import Data from '../../../shared/dom/data';

// -------
// Privat
// -------

let container;
let searchForm;
let searchField;
let searchFieldMin;

const setupSearchForm = () => {
	const submitButton = SelectorEngine.findOne('button[type="submit"]', searchForm);

	searchFieldMin = parseInt(searchField.getAttribute('min') ?? 0, 10);

	// Formular-Submit prüfen.
	EventHandler.on(searchForm, 'submit', event => {
		if (Data.get(searchForm, 'searchEnabled') !== true) {
			event.preventDefault();
			event.stopPropagation();
		}
	});

	// Formular prüfen auf aktiv oder inaktiv.
	EventHandler.on(searchForm, 'search.handleSubmit', () => {
		if (searchField.value !== '' && searchField.value.length >= searchFieldMin) {
			Data.set(searchForm, 'searchEnabled', true);
			Manipulator.setDisabled(submitButton, false);

		} else {
			Data.set(searchForm, 'searchEnabled', false);
			Manipulator.setDisabled(submitButton);
		}
	});

	// Eingabefeld: bei Eingabe Formular prüfen
	EventHandler.on(searchField, 'keyup', () => {
		EventHandler.trigger(searchForm, 'search.handleSubmit');
	});

	// Eingabefeld: wenn per Klick ´geleert´, dann Formular prüfen
	EventHandler.on(searchField, 'clearable.clear', () => {
		EventHandler.trigger(searchForm, 'search.handleSubmit');
	});

	// Formular prüfen
	EventHandler.trigger(searchForm, 'search.handleSubmit');
};

// -------
// Public
// -------

const search = async () => {
	container   = SelectorEngine.findOne('#modal-search');
	searchForm  = container ? SelectorEngine.findOne('form', container) : null;
	searchField = searchForm ? SelectorEngine.findOne('.search-box__input', searchForm) : null;

	if (searchField) {
		await setupSearchForm();

		container.addEventListener('shown.bs.modal', (event) => {
			setTimeout(() => {
				searchField.focus();
			}, 350);
		});
	}

	return container;
};

// Export
export default search;
